import React from "react";

function Donttake() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:pt-10 pt-6 md:pb-20 pb-10 px-8 ">
        <div>
          <h1>Don't take our word for it.</h1>
          <p>
            Here's what customers like you say about Vonage Unified
            Communications.
          </p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
          <div>
            <p>
              Great Service provider for businesses Vonage application is
              installed in our desktops to make and receive unlimited voice
              calls, texts and messages. The user interface looks great and
              simple to use. We installed this app in our mobiles as it can run
              in Android as well as IOS devices.{" "}
            </p>
            GARTNER
          </div>
          <div>
            <p>
              Great Service provider for businesses Vonage application is
              installed in our desktops to make and receive unlimited voice
              calls, texts and messages. The user interface looks great and
              simple to use. We installed this app in our mobiles as it can run
              in Android as well as IOS devices.{" "}
            </p>
            GARTNER
          </div>
          <div>
            <p>
              Great Service provider for businesses Vonage application is
              installed in our desktops to make and receive unlimited voice
              calls, texts and messages. The user interface looks great and
              simple to use. We installed this app in our mobiles as it can run
              in Android as well as IOS devices.{" "}
            </p>
            GARTNER
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donttake;
