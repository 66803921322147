import React from "react";
import Header from "./Header";
import Speak from "./../../components/Speak";
import Pricing from "./Pricing";
import data from "./../../data";
import Cards2 from "./Cards2";
import Discover from "./Discover";
import SigningOn from "./SigningOn";
import Whenphoning from "./Whenphoning";
import CheckOut from "./CheckOut";
import Donttake from "./Donttake";
function Index({location}) {
  return (
    <div>
      <Header />
      <Donttake />
      <Whenphoning />
      <SigningOn />
      <Discover />
      <CheckOut />
      <Cards2 card2={data.card2} />
      <Pricing />
      <Speak  location={location}/>
    </div>
  );
}

export default Index;
