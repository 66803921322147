import React from "react";
import RightImg1 from "./../../images/Calls.png";
import LeftImg2 from "./../../images/Laptop_VideoHello.png";
import LeftImg1 from "./../../images/illustration_smart-wan-clouds2.png";

function Whenphoning() {
  return (
    <div>
      <div className="bg-grey-normal">
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
          <div className="lg:pl-20 pl-8">
            <p className="font-normal text-xs mb-4">VOICE</p>
            <h1 className="text-3xl font-medium mb-4">
              When phoning it in is a good thing
            </h1>
            <p className="text-lg font-normal mb-16">
              Sometimes you just got to talk. But sometimes you got to add call
              tags to individual numbers to distinguish calls, too. From simple
              to space-age features, we got 'em.
            </p>
            <div className="flex justify-between">
              <ul className="list-disc ml-5">
                <li> Click-to-Dial</li>
                <li> Paperless Fax</li>
                <li>Call Monitoring</li>
              </ul>
              <ul className="list-disc ml-5">
                <li> Local or Geographic Number</li>
                <li>Call Flip</li>
                <li>Virtual Receptionist</li>
              </ul>
            </div>

            <div className="mt-10">
              <a
                href="/"
                className="underline uppercase font-medium tracking-widest text-sm"
              >
                MORE BUSINESS PHONE FEATURES
              </a>
            </div>
          </div>
          <div>
            <img src={RightImg1} alt="" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
          <div className="md:block hidden">
            <img src={LeftImg2} alt="" />
          </div>
          <div className="lg:pl-20 pl-8">
            <p className="font-normal text-xs mb-4">VIDEO COLLABORATION</p>
            <h1 className="text-3xl font-medium mb-4">
              Collaborate easily with colleagues, from wherever you are
            </h1>
            <p className="text-lg font-normal mb-16">
              Make teamwork make the dream work, and connect with your customers
              from virtually anywhere with Vonage Meetings.
            </p>
            <div className="flex justify-between">
              <ul className="list-disc ml-5">
                <li>Available for desktop & mobile</li>
                <li>Offers voice and video collaboration, plus chat</li>
                <li>Schedule meetings on the fly, right from your calendar</li>
              </ul>
              <ul className="list-disc ml-5">
                <li>Up to 150 attendees for training & webinars</li>
                <li>Up to 25 simultaneous videos while in session</li>
                <li>Easily record & share meetings</li>
              </ul>
            </div>
            <div className="mt-10">
              <a
                href="/"
                className="underline uppercase font-medium tracking-widest text-sm"
              >
                CHECK OUT VONAGE MEETINGS
              </a>
            </div>
          </div>
          <div className="md:hidden block">
            <img src={LeftImg2} alt="" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
          <div className="lg:pl-20 pl-8">
            <p className="font-normal text-xs mb-4">SD-WAN</p>
            <h1 className="text-3xl font-medium mb-4">
              Set your priorities straight
            </h1>
            <p className="text-lg font-normal mb-16">
              SmartWAN, our SD-WAN solution, lets you prioritize your
              bandwidth-intensive cloud applications, giving you the quality of
              service you can rely on for VBC or embedded voice into VBC for
              Teams. Available only in the US.
            </p>
            <div className="flex justify-between">
              <ul className="list-disc ml-5">
                <li>Bandwidth prioritization</li>
                <li>Encryption</li>
                <li>Firewall optimization</li>
              </ul>
              <ul className="list-disc ml-5">
                <li>Redundancy</li>
                <li>Congestion prevention</li>
                <li>Performance statistics</li>
              </ul>
            </div>
            <div className="mt-10">
              <a
                href="/"
                className="underline uppercase font-medium tracking-widest text-sm"
              >
                LEARN ABOUT SMARTWAN
              </a>
            </div>
          </div>
          <div>
            <img src={LeftImg1} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Whenphoning;
