import React from "react";
import Img1 from "./../../images/intouch_health.png";
import Img2 from "./../../images/dominos.png";
import Img3 from "./../../images/allstate.png";
import Img4 from "./../../images/the_princeton_review.png";
import Img5 from "./../../images/kickstarter.png";
import Img6 from "./../../images/doxy_me.png";
import Img7 from "./../../images/Leading_Lights.png";
import Img8 from "./../../images/IDC.jpeg";
import Img9 from "./../../images/Frost_Sullivan.jpeg";
import Img10 from "./../../images/Firestarter.jpeg";
import Img11 from "./../../images/Aragon_Research.jpeg";
import Img12 from "./../../images/2020-IT-POTY.png";
import Img13 from "./../../images/g2-leader-fall (1).png";
import RightImg1 from "./../../images/00-UC-Globe.png";
import RightImg2 from "./../../images/Yealink_SIP-T46G.png";
import LeftImg1 from "./../../images/People_DoctorPatientConsultation.png";
import LeftImg2 from "./../../images/Microsoft_Office_Teams_lg.png";

function Discover() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:pt-10 pt-6 md:pb-20 pb-10">
        <div className="flex w-full flex-wrap justify-center">
          <div>
            <img src={Img1} alt="" />
          </div>
          <div>
            <img src={Img2} alt="" />
          </div>
          <div>
            <img src={Img3} alt="" />
          </div>
          <div>
            <img src={Img4} alt="" />
          </div>
          <div>
            <img src={Img5} alt="" />
          </div>
          <div>
            <img src={Img6} alt="" />
          </div>
        </div>
      </div>
      <div className="bg-grey-normal">
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
          <div className="md:block hidden w-full">
            <div className="flex justify-center">
              <img src={LeftImg2} alt="" className="w-80" />
            </div>
          </div>
          <div className="lg:pl-20 pl-8">
            <p className="font-normal text-xs mb-4">DEVELOPER RESOURCES</p>
            <h1 className="text-3xl font-medium mb-4">
              By developers, for developers
            </h1>
            <p className="text-lg font-normal mb-16">
              Community is a wonderful thing. Connect with ours to discover best
              practices, untapped solutions, helpful docs, guides, events,
              blogs, and more. Learn, build, and share with like-minded
              thinkers.
            </p>
            <a
              href="/"
              className="underline uppercase font-medium tracking-widest text-sm"
            >
              VISIT OUR DEVELOPER CENTER
            </a>
          </div>
          <div className="md:hidden block">
            <div className="flex justify-center items-center pt-10">
              <img src={LeftImg2} alt="" className="w-80" />
            </div>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
          <div className="lg:pl-20 pl-8">
            <p className="font-normal text-xs mb-4">VONAGE APIS</p>
            <h1 className="text-3xl font-medium mb-4">
              Keep customers connected (and protected) with programmable
              messaging, voice, video and more
            </h1>
            <p className="text-lg font-normal mb-16">
              Gather your group with our{" "}
              <a href="/" className="underline">
                Video API
              </a>
              —great for live interactive video calls, virtual tech support,
              telehealth appointments, remote learning, and more. Mitigate fraud
              with our{" "}
              <a href="/" className="underline">
                Verify API
              </a>{" "}
              to build convenient two-factor authentication. Or get social with
              real-time messaging on popular social apps, like Facebook
              Messenger, WhatsApp, and Viber. Vonage does that (and a lot more).
            </p>
            <a
              href="/"
              className="underline uppercase font-medium tracking-widest text-sm"
            >
              SEE ALL VONAGE APIS
            </a>
          </div>
          <div>
            <img src={RightImg1} alt="" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
          <div className="md:block hidden">
            <img src={LeftImg1} alt="" />
          </div>
          <div className="lg:pl-20 pl-8">
            <p className="font-normal text-xs mb-4">DEVELOPER RESOURCES</p>
            <h1 className="text-3xl font-medium mb-4">
              By developers, for developers
            </h1>
            <p className="text-lg font-normal mb-16">
              Community is a wonderful thing. Connect with ours to discover best
              practices, untapped solutions, helpful docs, guides, events,
              blogs, and more. Learn, build, and share with like-minded
              thinkers.
            </p>
            <a
              href="/"
              className="underline uppercase font-medium tracking-widest text-sm"
            >
              VISIT OUR DEVELOPER CENTER
            </a>
          </div>
          <div className="md:hidden block">
            <img src={LeftImg1} alt="" />
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 py-20 place-items-center">
          <div className="lg:pl-20 pl-8">
            <p className="font-normal text-xs mb-4">DEDICATED SERVICES</p>
            <h1 className="text-3xl font-medium mb-4">
              Think big and build fast with the enterprise service plan
            </h1>
            <p className="text-lg font-normal mb-16">
              Success is not only choosing the right product, it’s having the
              know-how to get the most from it. We're your partner for
              consulting expertise, integration services, platform uptime, and
              enterprise-grade support—all in a single package.
            </p>
            <a
              href="/"
              className="underline uppercase font-medium tracking-widest text-sm"
            >
              SEE OUR SUPPORT PLANS
            </a>
          </div>
          <div>
            <img src={RightImg2} alt="" />
          </div>
        </div>
      </div>
      <div className="max-w-7xl m-auto md:pt-10 pt-6 md:pb-20 pb-10">
        <div className="text-center">
          <h1 className="text-2xl font-medium">
            Discover why the world's leading analysts honor Vonage year after
            year
          </h1>
        </div>
        <div className="flex justify-between items-center  px-6 mt-10">
          <div>
            <img src={Img7} alt="" />
          </div>
          <div>
            <img src={Img8} alt="" />
          </div>
          <div>
            <img src={Img9} alt="" />
          </div>
          <div>
            <img src={Img10} alt="" />
          </div>
        </div>
        <div className="flex w-full justify-evenly items-center  px-6 md:pt-20 mt-10">
          <div>
            <img src={Img11} alt="" />
          </div>
          <div>
            <img src={Img12} alt="" />
          </div>
          <div>
            <img src={Img13} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Discover;
