import React from "react";
import UniImg from "./../../images/PM_VBC_2020_02.jpg";

function Header() {
  return (
    <div>
      {" "}
      <div className="header-gradient">
        <div className="max-w-7xl m-auto md:py-24 py-12 px-8">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
            <div>
              <h1 className=" font-medium text-white md:text-5xl text-4xl mb-4">
              Unified communications
              </h1>
              <p className="md:text-xl text-lg font-normal text-white mb-16">
              Vonage Business Communications (VBC) activates conversations worldwide across voice,
               SMS, team messaging, fax, social, video meetings, and more.
              </p>
              <div className="flex flex-wrap md:justify-start justify-center">
                <a
                  href="/"
                  className="bg-white border-white border-2 text-black px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                 See plans and pricing
                </a>
                <a
                  href="/"
                  className="border-white border-2 text-white px-8 mb-6 mr-6 py-6 rounded-lg"
                >
                  Schedule a conversation
                </a>
              </div>
              <p className="text-white text-xs font-normal">¹Vonage's services, or specific features or functionality of the services, may not be available in all locations. Please contact your sales representative for current availability.

²The 99.999% claim is based on Vonage's average uptime and/or availability.</p>
            </div>
            <div>
              <img src={UniImg} alt="" />
              <p className="text-white text-xs font-normal mt-3 text-center">See how Vonage Business Communications can help
                   your company communicate: watch now.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
