import React from "react";
import Check from "./../../images/App-Center_small.png";

function CheckOut() {
  return (
    <div>
      <div className="max-w-7xl m-auto md:pt-10 pt-6 md:pb-20 pb-10 px-8 ">
        <div className="relative overflow-hidden bg-purple-primary px-16 py-20 rounded-3xl grid lg:grid-cols-2 grid-cols-1 lg:place-items-start place-items-center">
          <div className="px-8">
            <h1 className="font-medium text-5xl text-white mb-5">
              Check out the App Center
            </h1>
            <p className="text-xl font-normal text-white mb-5">
              See all the apps, tools, and features you can easily add onto the
              VBC platform.
            </p>
            <div className="mt-10">
              <a href="/" className="bg-white rounded-lg p-5">
                Learn More
              </a>
            </div>
          </div>
          <div className="lg:block hidden">
            <img src={Check} alt="" className="rounded-xl absolute  -right-8" />
          </div>
          <div className="lg:hidden block mt-10">
            <img src={Check} alt="" className="rounded-xl " />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckOut;
